<template>
	<div class="my-account">
		<div class="header-menu va-row">
			<div class="flex md10 offset--md1">
				<div class="body-spacing">
					<div class="header-teacher">
						<div class="va-row">
							<div class="flex xs6 ddh-item">
								Dentro Educa – {{ teacherCredential.user.client_name }}
							</div>
							<div class="flex xs6 ddh-prof">
								<router-link class="" :to="{ name: 'main' }">
									Encerrar sessão
									<i class="fa fa-sign-out" aria-hidden="true"></i>
								</router-link>

								<img
									:src="teacherCredential.user.client_logo"
									class="logo-client"
									alt="logo"
									width="120"
								/>
							</div>
						</div>

						<div class="va-row">
							<div class="flex xs12 text-left school-name">
								<div>
									<router-link :to="{ name: 'grades' }">
										<span class="back-circle">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="36"
												height="36"
												viewBox="0 0 36 36"
											>
												<path
													fill="#AFA2BA"
													fill-rule="evenodd"
													stroke="#AFA2BA"
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="1.6"
													d="M15.123 11.294a1.004 1.004 0 0 1 0 1.42l-4.282 4.282h17.156a1.003 1.003 0 1 1 0 2.007H10.84l4.282 4.284a1.004 1.004 0 0 1-1.419 1.42L7 18l6.705-6.706a1.003 1.003 0 0 1 1.418 0z"
												/>
											</svg>
										</span>
									</router-link>

									<h4>{{ book.name }}</h4>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- <div class="teacher-grade va-row">
				<div class="flex md10 offset--md1 no-padding-bottom">
					<div class="body-spacing">
						<div class="va-row">
							<div class="flex md6 xs12 no-padding">
								<menu-tabs
									:items="[
										{ label: 'Minhas turmas', slug: 'grades', isActive: false },
										{
											label: 'Livros personalizados',
											slug: 'infobook',
											isActive: true,
										},
										{
											label: 'Material complementar',
											slug: 'extra',
											isActive: false,
										},
									]"
									@scrollTo="scrollParent"
									ref="tabs"
									class="container-ddh-menu"
								></menu-tabs>
							</div>

							<div class="flex md6 xs12 text-right no-padding">
								<router-link
									class="btn btn-primary"
									target="_blank"
									:to="{ name: 'LoginStudent' }"
								>
									ÁREA DO ALUNO <span class="fa fa-external-link"></span>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div> -->
		</div>

		<div class="body-teacher" v-if="products.length > 0">
			<div class="va-row">
				<div class="flex md10 offset--md1">
					<div class="body-spacing">
						<div class="tab">Livros disponíveis</div>

						<!-- <div class="list" v-if="products.length > 5">
							<select-normal
								:items="
									products.map((i, index) => {
										return { value: i.name, key: index };
									}, [])
								"
								:value="''"
								label="Selecione um livro para visualizar"
								name="selectProduct"
								v-model="selectProduct"
								@change="changeBookSelect($event)"
							/>
						</div> -->

						<div class="panel item-list">
							<div class="va-row">
								<div class="flex md2 xs12 text-center align-image">
									<img :src="book.thumbnail" width="330" alt="" />
								</div>

								<div class="flex md8 xs12 align-image">
									<div>
										<!-- <h3 class="fweight-black" v-html="book.content_title"></h2> -->
										<h4>
											<span class="nome-da-marca">{{ book.brand.name }}</span
											><br />{{ book.name }}
										</h4>
										<h3 class="fweight-black" v-html="book.content_text"></h3>
										<h2 class="fweight-bold" v-html="book.content_text_2"></h2>

										<!-- <p
												class="fsize-16 fsize-sm-16 fsize-xl-18 mt-10"
												v-html="product_item.content_text"
											></p>
											<p
												class="fsize-16 fsize-sm-16 fsize-xl-18 mt-10"
												v-html="product_item.content_text_2"
											></p> -->
									</div>
								</div>

								<!-- <div class="flex md2 xs12 text-center align-image">
										<button class="btn" @click="viewBook(product_item)">
											VISUALIZAR
										</button>
									</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mask } from "vue-the-mask";
	import { mapGetters } from "vuex";

	const axios = require("axios");

	export default {
		name: "InfoBookDescription",
		directives: { mask },
		// components: { VuesticTreeCategory, VuesticTreeNode, VuesticTreeRoot },
		computed: {
			...mapGetters({
				teacherCredential: "teacherCredential",
				// ...
			}),
		},

		props: ["book"],

		data() {
			return {
				products: [],
				upload: false,
				selectedProduct: null,
				selectProduct: null,
			};
		},

		mounted() {
			const self = this;

			if (self.teacherCredential.project_id.constructor !== Array) {
				self.getBooks();
			} else {
				self.teacherCredential.project_id.forEach(function (project, i) {
					self.getBooksMultiple(project.id);
				});
			}
		},

		methods: {
			viewBook() {
				const self = this;

				self.$router.push({ name: slug });
			},

			getBooks() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.teacherCredential.token,
					},
				};

				axios
					.get(
						process.env.VUE_APP_ROOT_API +
							"/products/available/" +
							self.teacherCredential.project_id,
						config
					)
					.then((response) => {
						self.products = response.data.data.products;
						self.selectedProduct = 0;
					})
					.catch((e) => {
						this.errors.push(e);
					});
			},

			getBooksMultiple(project_id) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.teacherCredential.token,
					},
				};

				axios
					.get(
						process.env.VUE_APP_ROOT_API + "/products/available/" + project_id,
						config
					)
					.then((response) => {
						response.data.data.products.forEach(function (product, i) {
							// self.getBooksMultiple(project.id);
							self.products.push(product);
						});

						// self.products.push(response.data.data.products);
						self.selectedProduct = 0;
					})
					.catch((e) => {
						this.errors.push(e);
					});
			},

			changeBook(status) {
				this.selectedProduct = status;
			},

			changeBookSelect(event) {
				this.selectedProduct = event.target.value;
			},

			scrollParent(slug) {
				this.$router.push({ name: slug });
			},

			showSchool(school, type) {
				this.schoolModalType = type;
				this.schoolSelected = school;
				this.$refs.largeModal.open();
			},

			showGrade(grade, school, type) {
				this.gradeModalType = type;
				this.gradeSelected = grade;
				this.schoolSelected = school;
				this.$refs.gradeModal.open();
			},

			closeModal() {
				this.$refs.largeModal.close();
				this.$refs.gradeModal.close();
			},

			clickOnFile() {
				this.$refs.files.click();
			},

			submitFiles() {
				let formData = new FormData();

				for (var i = 0; i < this.files.length; i++) {
					let file = this.files[i];
					formData.append("csv", file);
				}

				axios
					.post(process.env.VUE_APP_ROOT_API + "/schools/import", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
							Authorization: "Bearer " + this.userCredential.token,
						},
					})
					.then(function () {
						alert("Upload feito com sucesso");
					})
					.catch(function () {
						alert("Erro, upload falhou");
					});
			},

			handleFileUpload() {
				this.files = this.$refs.files.files;
				this.submitFiles();
			},

			deleteSchool(id, key) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.userCredential.token,
					},
				};

				if (confirm("Você deseja excluir este item?")) {
					axios
						.delete(process.env.VUE_APP_ROOT_API + "/schools/" + id, config)
						.then((response) => {
							self.list.splice(key, 1);
							//self.$router.push({ name: 'schools' })
						})
						.catch((e) => {
							this.errors.push(e);
						});
				}
			},

			hidePopUp() {
				this.showPopUp = false;
			},

			showSubscriberEditing() {
				this.subscriberEditing = false;
			},
			hideSubscriberEditing() {
				this.subscriberEditing = false;
			},

			scrollParent(slug) {
				this.$router.push({ name: slug });
			},
		},
	};
</script>
<style lang="scss" scoped>
	.vuestic-tree-category {
		border-top: 1px solid #dfdae4;
		padding-top: 25px !important;
	}

	.align-image {
		img {
			max-width: 150px;
			margin: 0 auto;
		}
	}

	.item-list {
		font-family: "Nunito", "Helvetica Neue", Helvetica, Arial, sans-serif;

		h2 {
			font-family: "Roboto";
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			margin: 0px;
			position: relative;
			top: 10px;
			/* identical to box height, or 150% */

			/* color/neutral/darkest */

			color: #68596e;
		}

		h3 {
			font-family: "Roboto";
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 21px;
			position: relative;
			top: 10px;
			/* identical to box height, or 131% */

			/* color/neutral/darkest */

			color: #68596e;
			margin: 0px;
		}
	}

	.questions {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.43;
			letter-spacing: normal;
			color: #68596e;
		}

		::v-deep .square-with-icon {
			background-color: transparent;
			color: #00dcde;
			font-size: 35px;
		}
	}

	.info-box {
		border-radius: 10px;
		background-color: #f8f7fc;
		height: 215px;
		border-radius: 10px;
		background-color: #f8f7fc;
		padding: 34px;
	}
	.btn-white {
		position: relative;
	}
	.flag {
		box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3);
		background-color: #4567ba;
		max-width: 310px;
		padding: 10px;
		width: 310px;
		position: absolute;
		color: #cbe1ff;
		font-size: 14px;
		font-family: "Nunito";
		border-radius: 10px;
		display: block;
		margin-left: -90px;
		text-align: left;
		font-weight: 200;
		bottom: 63px;

		p {
			margin-bottom: 0px !important;
		}

		.icon {
			align-items: center;
			display: flex;
		}

		b {
			color: #cbe1ff !important;
		}

		&:before {
			content: "";
			position: absolute;
			bottom: -20px;
			width: 0px;
			height: 0px;
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-top: 20px solid #4567ba;
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.body-spacing {
		max-width: 1160px;
		margin: 0 auto;
	}
	::v-deep .box-blue {
		height: 215px;
		border-radius: 10px;
		background-color: #00dcde;
		padding: 25px;
		font-size: 16px;
		color: #ffffff;

		h1 {
			font-family: Nunito;
			font-size: 60px;
			font-weight: 800;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #ffffff;
		}
	}
	::v-deep .vuestic-tabs .nav {
		padding-top: 0px !important;
	}

	::v-deep .vuestic-tabs {
		padding: 0px !important;
	}

	.phrase-secure {
		font-family: Roboto;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: #68596e;
		margin-top: 20px !important;
		// margin-bottom:20px !important;

		b {
			color: #68596e !important;
		}

		.btn {
			height: 65px;
			width: 100%;
			max-width: 357px;
			font-size: 14px;
			border-radius: 100px;
		}
	}

	.custom-teacher-table {
		font-family: Roboto;
		font-size: 14px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #afa2ba;

		b {
			font-size: 14px !important;
		}

		.header-table {
			height: 61.5px;
			background-color: #f8f7fc;
			border-top: solid 1px #dfdae4;
			line-height: 33px;
		}
	}
	.teacher-flag {
		font-family: Roboto;
		font-size: 14px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #ffffff;
		background-color: #9378f0;
		line-height: 30px;

		a {
			font-weight: bold;
			text-decoration: underline;
			color: white;
		}
	}
	.book-covers {
		justify-content: flex-end;
	}
	.empty-book {
		width: 100%;
		border: 1px solid #afa2ba;
		height: 100%;
		height: 187px;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		display: flex;
		font-size: 25px;
	}
	.btn-micro-edit {
		font-family: "Nunito";
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 2;
		letter-spacing: 0.67px;
		text-align: center;
		color: #4965bc;
		background: none;
		border: none;
	}
	.student-name {
		font-family: "Roboto";
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #68596e;

		justify-content: center;
		flex-direction: column;
		text-align: left;
		display: flex;
	}
	.student-code {
		font-family: Nunito;
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: 1.11px;
		text-align: right;
		color: #68596e;

		justify-content: center;
		flex-direction: column;
		text-align: right;
		display: flex;
	}

	.teacher-grade {
		background-color: white;
		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #68596e;

		.btn {
			width: 275px;
			height: 45px;
			border-radius: 100px;
			background-color: #9378f0;
			background-image: none;
			position: relative;
			line-height: 20px;
			top: 3px;

			@media (max-width: 767px) {
				display: block;
				margin: 0 auto;
				top: 15px;
			}

			&:hover {
				background-color: #9378f0;
			}
			span {
				font-size: 17px;
				position: absolute;
				top: 13px;
				right: 23px;
			}
		}

		.teacher-name {
			line-height: 45px;
		}
	}

	.panel-gray {
		border-radius: 10px;
		border: solid 1px #dfdae4;
		background-color: #ffffff;
		overflow: hidden;

		.btn {
			border-radius: 100px;
			background-color: #4965bc;
			height: 65px;
			border-radius: 100px;
			background-color: #4965bc;
			padding-left: 70px;
			position: relative;
			font-size: 14px;
			margin-top: 20px;
			margin-bottom: 20px;
			line-height: 40px;

			span {
				width: 45px;
				height: 45px;
				border-radius: 100px;
				background-color: #ffffff;
				font-size: 41px;
				display: inline-block;
				color: #4965bc;
				line-height: 43px;
				position: absolute;
				left: 8px;
				top: 8px;
			}
		}
	}

	.body-teacher {
		margin-top: 20px;

		.body-spacing .panel {
			padding: 24px;

			h4 {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				color: #68596e;
				margin-top: 0;
			}
		}
	}

	.body-student {
		border-bottom: 1px solid #dfdae4;
	}

	.info-grade {
		padding-top: 20px;
		padding-bottom: 20px;
		font-family: "Roboto";
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #68596e;
	}

	.school-name {
		font-family: "Nunito";
		font-size: 40px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 10px;
		letter-spacing: normal;
		color: #ffffff;
		margin-bottom: 10px;
		margin-top: 10px;

		h4 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
			margin-top: 0;
			// line-height: 70px;
		}

		span {
			font-size: 16px;
			font-weight: 200 !important;
			margin-bottom: 10px;
			margin-top: 10px;
		}

		.back-circle {
			width: 60px;
			height: 60px;
			box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
			background-color: #ffffff;
			// display: inline-block;
			text-align: center;
			border-radius: 30px;
			position: relative;
			float: left;
			margin-right: 10px;
			top: 0px;
			margin-top: 0px;

			svg {
				position: absolute;
				left: 0px;
				right: 0px;
				top: 0px;
				bottom: 0px;
				margin: auto;
			}
		}
	}

	.my-account {
		position: absolute;
		top: 0px;
		background-color: #f8f7fc;
		z-index: 3;
		width: 100%;
		padding-bottom: 30px;
		min-height: 100%;
		overflow: hidden;
		// height: 100%;
	}

	.header-teacher {
		position: relative;

		.ddh-item {
			font-family: Roboto;
			font-size: 12px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #fff;
		}

		.ddh-prof {
			font-family: Roboto;
			font-size: 12px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: right;
			color: #ffffff;

			a {
				border: 0;
				background: none;
				font-family: Roboto;
				font-size: 12px;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: right;
				color: #ffffff;
			}
		}
	}

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		// letter-spacing: 1.3px;
		color: #000000;
		margin-bottom: 0;
	}
	.header-menu {
		background-color: #00cf72;
		margin-bottom: 20px;
		display: block;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}

		.logo-client {
			position: absolute;
			bottom: 0px;
			display: block;
			right: 0px;
			margin: 0px;
			float: right;

			@media (max-width: 990px) {
				display: none;
			}
		}

		// .school-name-short {
		// 	font-family: "Nunito";
		// 	font-style: normal;
		// 	font-weight: 900;
		// 	font-size: 16px;
		// 	line-height: 22px;
		// 	text-transform: capitalize;
		// 	color: #68596e;
		// 	display: flex;
		// 	align-items: center;
		// }

		// .school-name {
		// 	font-family: "Nunito";
		// 	font-size: 40px;
		// 	font-weight: 900;
		// 	font-style: normal;
		// 	font-stretch: normal;
		// 	line-height: 10px;
		// 	letter-spacing: normal;
		// 	color: #ffffff;
		// 	margin-bottom: 10px;
		// 	margin-top: 10px;
		// 	align-items: center;
		// 	display: flex;

		// 	font-family: "Nunito";
		// 	font-size: 40px;
		// 	font-weight: 900;
		// 	font-style: normal;
		// 	font-stretch: normal;
		// 	line-height: 10px;
		// 	letter-spacing: normal;
		// 	color: #ffffff;
		// 	margin-bottom: 20px;

		// 	h4 {
		// 		font-family: "Nunito";
		// 		font-size: 40px;
		// 		font-weight: 900;
		// 		font-style: normal;
		// 		font-stretch: normal;
		// 		letter-spacing: normal;
		// 		color: #ffffff;
		// 		margin-top: 0;
		// 		display: flex;
		// 		align-items: center;
		// 		// line-height: 70px;
		// 	}

		// 	span {
		// 		font-size: 16px;
		// 		font-weight: 200 !important;
		// 		margin-bottom: 10px;
		// 		margin-top: 10px;
		// 	}

		// 	.back-circle {
		// 		width: 60px;
		// 		height: 60px;
		// 		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
		// 		background-color: #ffffff;
		// 		// display: inline-block;
		// 		text-align: center;
		// 		border-radius: 30px;
		// 		position: relative;
		// 		float: left;
		// 		margin-right: 10px;
		// 		top: 0px;
		// 		margin-top: 0px;
		// 		margin-bottom: 0px;

		// 		svg {
		// 			position: absolute;
		// 			left: 0px;
		// 			right: 0px;
		// 			top: 0px;
		// 			bottom: 0px;
		// 			margin: auto;
		// 		}
		// 	}
		// }

		.header-teacher {
			position: relative;

			.ddh-item {
				font-family: Roboto;
				font-size: 12px;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				color: #fff;
			}

			.ddh-prof {
				font-family: Roboto;
				font-size: 12px;
				font-weight: bold;
				font-style: normal;
				font-stretch: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: right;
				color: #ffffff;

				a {
					border: 0;
					background: none;
					font-family: Roboto;
					font-size: 12px;
					font-weight: bold;
					font-style: normal;
					font-stretch: normal;
					line-height: normal;
					letter-spacing: normal;
					text-align: right;
					color: #ffffff;
				}
			}
		}
	}

	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}

	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}

	// .address-info{
	//   margin-bottom:15px;
	// }
	// .editing{
	//   margin-top:15px;
	// }
	// .radio-button-wrapper{
	//   padding: 14px 11px;
	//   margin-top: 10px;

	//   @media(max-width: 990px){
	//     padding: 20px 19px;
	//     margin-top: 10px;
	//   }
	// }
	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 0px 0px 10px 10px;
		// border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		// margin-bottom: 20px;
		box-shadow: 0 10px 25px 0 #e2ddf6;
		background-color: #ffffff;
		margin-top: 2px;
		position: relative;
		z-index: 999;
		border-left: 1px solid #dfdae4;
		border-right: 1px solid #dfdae4;
		border-bottom: 1px solid #dfdae4;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		// padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #000000;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		// margin-top:40px;
		padding: 31px;
		border: solid 2px #f0ebf5;

		@media (max-width: 990px) {
			margin-top: 0px;
			padding: 15px;
		}
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}

	.name-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
	.lastname-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}

	.no-padding-bottom {
		padding-bottom: 0px !important;
	}

	.logo-client {
		position: absolute;
		bottom: 0px;
		display: block;
		right: 0px;
		margin: 0px;
		float: right;

		@media (max-width: 990px) {
			display: none;
		}
	}
	.align-image {
		display: flex;
		align-items: center;
	}

	.tab {
		/* color/neutral/light */

		background: #f4f2fb;
		border: 1px solid #dfdae4;
		border-radius: 16px 16px 0px 0px;

		font-family: "Roboto";
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 14px;
		/* identical to box height */

		display: flex;
		align-items: center;
		text-transform: uppercase;

		color: #68596e;
		padding: 20px 22px;
	}

	.fweight-bold {
		font-weight: bold;
	}
	.nome-da-marca {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		color: #68596e;
	}

	@media (min-width: 768px) {
		.flex.md8 {
			flex-basis: 82.666667%;
			flex-grow: 0;
			max-width: 82.666667%;
		}
	}
</style>
